import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  // Link,
  // useLocation
} from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import { DevTool } from "little-state-machine-devtools";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Result from "./components/Result";

import "./styles.css";

createStore({
  yourDetails: {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    csvTable: [],
    csvTable_final: [],
    csvTableErrors: [],
    csvTableMeta: [],
    csvTableSelectedCol: "",
    csvTableTextData: [],
    textCol: "",
    colOptions: [],
    showTable: false,
    ShowTable_final: false,
  }
});

const Pages = () => {
  // const location = useLocation();
  return (
    <>
      {/* <nav className="container">
        <ul className="steps">
          <li className={location.pathname === "/" ? "active" : ""}>
            <Link to="/">Step 1</Link>
          </li>
          <li className={location.pathname === "/step2" ? "active" : ""}>
            <Link to="/step2">Step 2</Link>
          </li>
          <li className={location.pathname === "/result" ? "active" : ""}>
            <Link to="/result">Result</Link>
          </li>
        </ul>
      </nav> */}
      <Route exact path="/" component={Step1} />
      <Route path="/step2" component={Step2} />
      <Route path="/result" component={Result} />
    </>
  );
};

function App() {
  return (
    <StateMachineProvider>
      <DevTool />
      <div className="container">
        <div className="Header">
          <h1>WORDS<br></br>INISGHTS LAB<br></br> <span><i>Demo</i></span></h1>
          <span className="subtitle">
            <span className="emphasize">Sentiment analysis tools</span> are used to quickly detect sentiment in surveys, text data, customer support inquiries, social media and more.
            Try sentiment analysis for free using advanced algorithms by AAARL, and <a target="_blank" rel="noopener noreferrer" href="https://www.wordsinsights.com/">contact us</a> to learn how to 
            <span className="emphasize"> perform text analysis at scale</span>.<br></br>
            {/* Fill out the form below and upload a CSV table. */}
          </span>
        </div>
        <Router>
          <Pages />
        </Router>
      </div>
    </StateMachineProvider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
