import * as React from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';
// import { CSVReader } from 'react-papaparse';

// export default () => {
const Step1 = props => {
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: state.yourDetails
  });
  const { push } = useHistory();
  const onSubmit = data => {
    action(data);
    push("/step2");
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Fill out the form below</h2>
      <label>
        First Name:
        <input
          name="firstName"
          ref={register({ required: "This is required." })}
        />
        <ErrorMessage errors={errors} name="firstName" as="p" />
      </label>
      <label>
        Last Name:
        <input
          name="lastName"
          ref={register({ required: "This is required." })}
        />
        <ErrorMessage errors={errors} name="lastName" as="p" />
      </label>
      <label>
        Company:
        <input
          name="company"
          ref={register({ required: "This is required." })}
        />
        <ErrorMessage errors={errors} name="company" as="p" />
      </label>
      <label>
        Business Email Address:
        <input
          name="email"
          ref={register({ 
            required: "This is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address"
            }
          })}
        />
        <ErrorMessage errors={errors} name="email" as="p" />
      </label>

      <input type="submit" value="Next"/>
    </form>
    </>
  );
}
export default Step1;