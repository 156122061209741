import React, { useState, useEffect } from "react";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import Table from './table';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
// import { google } from "googleapis";
// const {JWT, auth} = require('google-auth-library');
// https://github.com/googleapis/google-auth-library-nodejs
// https://medium.com/google-cloud/securing-google-cloud-functions-using-service-account-and-how-to-access-it-in-service-service-582b4b5f210

// import SheetDB from 'sheetdb-js'


const Result = props => {
  const [ isLoading, setLoading ]  = useState(true);
  const { state, action } = useStateMachine(updateAction);
  const { push } = useHistory();


  const resetForm = data => {
    action({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      csvTable: [],
      csvTable_final: [],
      csvTableErrors: [],
      csvTableMeta: [],
      csvTableSelectedCol: "",
      showTable: false,
      ShowTable_final: false,
    });
    setLoading(true);
    push("/");
  };

  
  // const APIurl = 'http://localhost:8002/predict';
  // const GCFurl = 'https://northamerica-northeast1-aaarl-nlp.cloudfunctions.net/aaarl-nlp';
  const APIurl = 'https://aaarl-api-gateway-gsepeeqzia-nn.a.run.app/hello';

  // const SheetDB = "jqunxfjt";
  // const SheetDBpw = "i83iamz07bqz5ojkxbbe";
  // const SheetDBurl = 'http://localhost:8002/'
  const SheetDBurl = 'https://sheetdb.io/api/v1/0bvsiw4t7c65t';
  // const SheetDBtoken = Buffer.from(`${SheetDB}:${SheetDBpw}`, 'utf8').toString('base64')
  // {
  //   auth: {
  //     username: SheetDB,
  //     password: SheetDBpw
  //   }
  // },

  const keys = require('./aaarl-nlp-invoker_auth.json');
  const jwt = require('jsonwebtoken');

  async function generate_JWT() {
    const sa_email = 'aaarl-nlp-invoker@aaarl-nlp.iam.gserviceaccount.com';
    const audience = 'aaarl-api-gateway-gsepeeqzia-nn.a.run.app';

    const token = {
      iat: parseInt(Date.now() / 1000),
      exp: parseInt(Date.now() / 1000) + 3600,
      iss:  sa_email,
      aud: audience,
      sub: sa_email,
      email: sa_email,
    };
    
    const jwtSigned = jwt.sign( token, keys.private_key, {algorithm: 'RS256'} );
    return jwtSigned
  }

  async function predict_JWT() {
    
    const jwtSigned = await generate_JWT();
    const decoded_jwt = decodeURIComponent( escape( jwtSigned) );

    const headers = {
      'Authorization': `Bearer {${decoded_jwt}}`,
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    const axiosConfig = {
      headers
    };

    const data_toPredict = {
      "nlabel":5,
      "text_data":state.yourDetails.csvTable.map(data => {return ( data[state.yourDetails.csvTableSelectedCol.label])})
    };

    axios.put(
      APIurl,
      data_toPredict,
      axiosConfig
    )
    .then(res => {
      let csvTable_final_temp = state.yourDetails.csvTable.map((row, index) => ({...row, Prediction: res.data[index]}));
      state.yourDetails.csvTable_final = csvTable_final_temp;
      console.log(csvTable_final_temp);
      action({
        csvTable_final: csvTable_final_temp,
        ShowTable_final: true,
      });
      setLoading(false);
    })
    .catch(err => {
      console.log(err)
      setLoading(true);
      state.yourDetails.ShowTable_final = false;
      action({
        ShowTable_final: false,
      });
    });
  }



  useEffect(() => {
    axios.post(SheetDBurl,
    { sheet: 'Sheet1', data: {
      "Timestamp":new Date(),
      "First Name":state.yourDetails.firstName,
      "Last Name":state.yourDetails.lastName,
      "Company":state.yourDetails.company,
      "Business Email Address":state.yourDetails.email,
      "csvTableMeta": state.yourDetails.csvTableMeta,
      "csvTableErrors": state.yourDetails.csvTableErrors
    }}).then(function(result){
      // console.log(result);
    }, function(error){
      console.log(error);
    });
    predict_JWT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <>
        <h1>loading</h1>
        <p>Result may take longer on Demo site</p>
        {/* <button type="clear" onClick={updatePrediction}> Update Prediction</button> */}
      </>
    )
  }

  return (
    <>
      <h2>Result</h2>
      <button type="submit">       <CSVLink 
        data={state.yourDetails.csvTable_final}
        filename={"words-insights-demo.csv"}
        className="btn"
      >
        Download Result
      </CSVLink></button>

      <div className="container">
        {/* <button type="clear" onClick={updatePrediction}> Update Prediction</button> */}
        {/* <h2>Result</h2> */}
        {/* <h3>Final Table</h3> */}
        {state.yourDetails.ShowTable_final && (
          <Table data={state.yourDetails.csvTable_final}/>
        )}
        <button type="clear" onClick={resetForm}> Reset Form</button>
      </div>
    </>
  );
};

export default Result;
