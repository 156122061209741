import React, {useState} from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import CSVParser from './csvparser';
import Table from './table';
import Select from "react-select";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const Step2 = props => {
  const { state, action } = useStateMachine(updateAction);
  const { handleSubmit, control, errors } = useForm({
    defaultValues: state.yourDetails
  });
  const { push } = useHistory();

  const onSubmit = data => {
    action(data);
    push("/result");
  };

  const handleOnRemoveFile = () => {
    action({
        csvTable: [],
        csvTable_final: [],
        csvTableErrors: [],
        csvTableMeta: [],
        csvTableSelectedCol: "",
        showTable: false,
        ShowTable_final: false
    })
  }

  // Styling for select
  const selectStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#ec5990':'white',
        color: 'black',
      };
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const MyVerticallyCenteredModal = (props) => {
    return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload a CSV file
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Step 1: Create a CSV file using Excel</h4>
          <ul>
            <li>Make sure your data has a header</li>
            <img width="100%" src={require('../img/csv1.png')} alt="Data must have a Header"></img>
            <li>Go to <b>File / Save as...</b> then chose <b>CSV</b> as the <b>File Format</b></li>
            <img width="100%" src={require('../img/csv2.png')} alt="Choose CSV as File Format"></img>
          </ul>

        <h4>Step 2: Upload the CSV file </h4>

          <ul>
              <li>Drop the CSV file in the box as instructed or click the box to upload.</li>
              <li>Make sure the file has less than 50 rows.</li>
              <img width="100%" src={require('../img/uploadCSV.png')} alt="Upload CSV"></img>
          </ul>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Upload a CSV file</h2>
      <span className="subheader"><i>* the CSV must have less than 50 rows</i></span>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Instructions
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {!state.yourDetails.showTable && (
        <CSVParser />
      )}
      {state.yourDetails.showTable && (
        <>
          <button type="clear" onClick={handleOnRemoveFile}>Clear CSV</button>
          <label>
            Select Text Column:
            <Controller
              name="csvTableSelectedCol"
              as={Select}
              options={state.yourDetails.colOptions.map((key, index) => {
                return {value: index+1, label: key }
              })}
              control={control}
              styles={selectStyles}
              defaultValue=""
              rules={{ required: true }}
            />
            {errors.csvTableSelectedCol && <p>This is required.</p>}
          </label>
          <Table data={state.yourDetails.csvTable }/>
          <input type="submit" value="Next"/>
        </>
      )}
    </form>
  );
};

export default Step2;
