import React from 'react';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';
import { CSVReader } from 'react-papaparse';

// export default class CSVParser extends Component

const CSVParser = props => {
    const { state, action } = useStateMachine(updateAction);    
    const handleOnDrop = (data) => {
        console.log(data);
        action({
            csvTable: data.map((data)=>{return (data.data)}),
            csvTableErrors: data.map((data)=>{return (data.errors)}),
            csvTableMeta: data.map((data)=>{return (data.meta)}),
            colOptions: Object.keys(data.map((data)=>{return (data.data)})[0]),
            showTable: true,
        })
        // console.log('---------------------------')
        // console.log(state.yourDetails.csvTable)
        // console.log('---------------------------')
        // console.log(state.yourDetails.colOptions)
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        action({
            csvTable: [],
            csvTable_final: [],
            csvTableErrors: [],
            csvTableMeta: [],
            showTable: false,
            ShowTable_final: false
        })
        console.log('---------------------------')
        console.log(state.yourDetails.csvTable);
    }

    const papaparseOptions = {
        header: true,
        skipEmptyLines: true,
        // https://react-papaparse.js.org/docs#config
    }
    const csvParserStyle = {
        dropArea: {
            borderStyle: 'dashed',
            borderColor: '#ccc',
            borderRadius: 20,
            borderWidth: 2,
            // backgroundColor: '#ccc',
            dropFile: {
            width: 200,
            height: 64,
            background: 'none',
            fileSizeInfo: {
                color: 'white',
                backgroundColor: 'none',
                borderRadius: 3,
                lineHeight: 1,
                // marginBottom: '0.5em',
                // padding: '0 0.4em',
            },
            fileNameInfo: {
                color: "white",
                backgroundColor: 'none',
                borderRadius: 3,
                fontSize: 14,
                lineHeight: 1,
                // padding: '0 0.4em',
            },
            removeButton: {
                // color: 'blue',
            },
            progressBar: {
                // backgroundColor: 'pink',
            },
            },
        },
    }

    return (
        <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            onRemoveFile={handleOnRemoveFile}
            config={papaparseOptions}
            style={csvParserStyle}
            >
            <span>Drop CSV file here or Click to upload.</span>
        </CSVReader>
        
    )
}
export default CSVParser;